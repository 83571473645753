import { RECEIVE_WORK_SAMPLES, RECEIVE_CURATED_WORK_SAMPLES } from '../actions/work_samples_actions';

const WorkSamplesReducer = (state = { worksamples: undefined, curatedworksamples: undefined}, action) => {
Object.freeze(state);
let newState = Object.assign({}, state);
switch(action.type) {
 case RECEIVE_WORK_SAMPLES:
   newState.worksamples = action.worksamples.data;
   return newState;
case RECEIVE_CURATED_WORK_SAMPLES:
    newState.curatedworksamples = action.curatedworksamples.data;
    return newState;
 default:
   return state;
}
};

export default WorkSamplesReducer;