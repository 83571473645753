import { connect } from 'react-redux';
import WorkSamples from './work_samples';
import {fetchWorkSamples} from '../../actions/work_samples_actions'

const mapStateToProps = (state) => {
  return {
    worksamples: state.worksamples.worksamples
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWorkSamples: (artist_id) => dispatch(fetchWorkSamples(artist_id)),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkSamples);