import * as Util from '../util/policies_util'

export const RECEIVE_POLICIES = "RECEIVE_POLICIES"

export const receivePolicies = policies => ({
    type: RECEIVE_POLICIES,
    policies
})

export const fetchPolicies = () => dispatch => {
    Util.fetchPolicies().then(policies => {
        dispatch(receivePolicies(policies))
    })
}