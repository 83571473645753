import React, { useState, useEffect, Suspense } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'


export default function Financing(props) {


    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

  

      useEffect(() => {

        
        }, [])

    return (
        <div className="add-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top Permanent Makeup Artists in the Lehigh Valley</title>
                <link rel="canonical" href="https://www.cosmetink.com/" />
                <meta name="description" content="Lehigh Valley's Award-Winning, Premier and First Targeted Permanent Makeup Studio & Academy - for all ages, skin types, and colors.  We specialize in nano brows, microblading, ombre brows, and lip blush.  We're located in the Lehigh Valley, Pennsylvania.  Conveniently located between New York City and Philadelphia" />
                <meta name="keywords" content="eyebrows permanent makeup cosmetics lehigh valley, pennsylvania, lehigh valley pa, lehigh valley pennsylvania, tattoo makeup, cosmetic ink, permanent makeup, ombre powder brows, microblading, lip blush, semi-permanent makeup" />
            </Helmet>    
            <br/>      
            <div className='title6'>
                30 Second Financing Approval
            </div>
        

                <br/>

            <div className='financing c'>
            <i class="fa-duotone fa-credit-card"></i>
             <h1 className='smaller2'>
                Apply Now
            </h1>
            <p className='c'>
                Payments as low as $30/month
            <br/>
            <p className='smaller c'>
            0% APR Options Available<br/>
            No Hard Credit Check<br/>
            </p>
            </p>

            <a className='email-link' href="mailto:cosmetinkbeauty@gmail.com?subject=30 Second Credit Application&body=Please send me a credit application to see how much I'm approved for.">
            Get Your 30 Second Application <br/> <br/>Email Us <i class="fa-sharp fa-light fa-up-right-from-square"></i>
            </a>
            </div>


        </div>
        
            

      
      )}