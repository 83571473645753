import * as Util from '../util/worksamples_util'

export const RECEIVE_WORK_SAMPLES = "RECEIVE_WORK_SAMPLES"
export const RECEIVE_CURATED_WORK_SAMPLES = "RECEIVE_CURATED_WORK_SAMPLES"

export const receiveWorkSamples = worksamples => ({
    type: RECEIVE_WORK_SAMPLES,
    worksamples
})

export const receiveCuratedWorkSamples = curatedworksamples => ({
    type: RECEIVE_CURATED_WORK_SAMPLES,
    curatedworksamples
})

export const fetchWorkSamples = (artist_id) => dispatch => {
    Util.fetchWorkSamples(artist_id).then(worksamples => {
        dispatch(receiveWorkSamples(worksamples))
    })
}

export const fetchCuratedWorkSamples = (type) => dispatch => {
    Util.fetchCuratedWorkSamples(type).then(curatedworksamples => {
        dispatch(receiveCuratedWorkSamples(curatedworksamples))
    })
}