import React, { useState, useEffect, Suspense } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'


export default function Contact(props) {


    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

  

      useEffect(() => {

        
        }, [])

    return (
        <div className="add-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top Permanent Makeup Artists in the Lehigh Valley</title>
                <link rel="canonical" href="https://www.cosmetink.com/" />
                <meta name="description" content="Lehigh Valley's Award-Winning, Premier and First Targeted Permanent Makeup Studio & Academy - for all ages, skin types, and colors.  We specialize in nano brows, microblading, ombre brows, and lip blush.  We're located in the Lehigh Valley, Pennsylvania.  Conveniently located between New York City and Philadelphia" />
                <meta name="keywords" content="eyebrows permanent makeup cosmetics lehigh valley, pennsylvania, lehigh valley pa, lehigh valley pennsylvania, tattoo makeup, cosmetic ink, permanent makeup, ombre powder brows, microblading, lip blush, semi-permanent makeup" />
            </Helmet>          
            <div className='title6'>
            <i class="fa-solid fa-map-pin"></i>&nbsp;&nbsp; Lehigh Valley, Pennsylvania
            <p className='smaller c'>
            Conveniently located 90 minutes from <br/>Philadelphia & NYC
            </p>
            </div>
        


            <div className='financing c'>
         
            <a className='email-link' href="https://www.google.com/maps/dir//3859+Nazareth+Pike+Unit+103,+Bethlehem,+PA+18020/@40.673803,-75.4283102,12z/data=!4m6!4m5!1m0!1m2!1m1!1s0x89c443557b11e64d:0x601c04db1170f5b6!3e0">
            Get Directions <i class="fa-sharp fa-light fa-up-right-from-square"></i>
            </a>
            <br/>

            <p className='c'>
            3859 Nazareth Pike<br/>
            Bethlehem, PA 18020<br/>
            <a className='email-link' href="mailto:cosmetinkbeauty@gmail.com">
            cosmetinkbeauty@gmail.com 
            </a>
            </p>

    
            <br/><br/>
            <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d773617.6840724369!2d-75.23010343128904!3d40.76325467428503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c443557b11e64d%3A0x601c04db1170f5b6!2sCOSMETINK%C2%AE%20Permanent%20Cosmetics%20Studio%20%26%20Academy!5e0!3m2!1sen!2sus!4v1657835141207!5m2!1sen!2sus" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>


        </div>    
        
        )}