import React, { useState, useEffect, Suspense } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'


export default function AboutUs(props) {

    const {aboutUs} = props

    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

  

      useEffect(() => {
        }, [])

    return (
        <div className="artist-page slide-in-bottom">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top Permanent Makeup Artists in the Lehigh Valley</title>
                <link rel="canonical" href="https://studio.cosmetink.com/artists" />
                <meta name="description" content="Lehigh Valley's Award-Winning, Premier and First Targeted Permanent Makeup Studio & Academy - for all ages, skin types, and colors.  We specialize in nano brows, microblading, ombre brows, and lip blush.  We're located in the Lehigh Valley, Pennsylvania.  Conveniently located between New York City and Philadelphia" />
                <meta name="keywords" content="eyebrows permanent makeup cosmetics lehigh valley, pennsylvania, lehigh valley pa, lehigh valley pennsylvania, tattoo makeup, cosmetic ink, permanent makeup, ombre powder brows, microblading, lip blush, semi-permanent makeup" />
            </Helmet>

            <i onClick={()=> aboutUs()}  class="fa-sharp fa-solid fa-circle-xmark"></i>
                <div className='artist-box2'>
                    <div className='artist-box-overlay2'></div>
                        <img src={`https://cosmetinklanding.s3.us-east-2.amazonaws.com/siblingduo.jpg`} className='artist-img3' />
                        <video loop muted autoPlay controls='' playsInline  src={`https://cosmetinklanding.s3.us-east-2.amazonaws.com/siblingduo.mp4`} className='artist-img4'/> 
                   
            </div>

            <div className='gradient-underline'></div>
                <div className='title6 l'>
               About Us
                </div>
                <p className='artist-bio'>
                Nikky @nikky.cosmetink & Sean @sean.cosmetink are a talented brother sister duo ✨🙌 working together like “Ying & Yang.” 
                <br/><br/>Coming together over a decade ago, the two started COSMETINK® with the mission to bring beautiful and natural permanent makeup to the Lehigh Valley and beyond.
                <br/>
                <br/>Nikky and Sean balance each other and bounce off each others' strong suits:
Nikky loves the arts & creativity of Permanent Makeup; whereas, Sean loves the anatomy & mathematics of it - when the two come together with their techniques it makes for perfect results 👩‍🎨👩‍🔬 🎨🧪
<br/>
<br/>
The two work tirelessly together to continue elevating their techniques and to foster a talented team of professionals who are extensively trained and skilled in offering hyper-realistic results.
<br/><br/>
Nikky is a licensed esthetician 💆‍♀️ and Sean has a degree in Chemistry,  which make the two very knowledgeable together when it comes to working in the skin 🖊️
<br/><br/>
Nikky and Sean have been in the beauty industry for a combined total of over 20 years, Nikky having done makeup for weddings & photoshoots as a professional makeup artist and Sean as a fashion model traveling the world to work with some of the biggest names in fashion - these experiences have gained the two very artistic and elevated eyes for beauty.
<br/><br/>
With these eyes for beauty and knowledge for skin, clients will get to experience both an artistic and scientific approach to permanent makeup, leaving with highly effective and beautiful results.
                
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                
                </p>
        </div>
        
            

      
      )}