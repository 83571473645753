import React, { useState, useEffect, Suspense } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import CuratedArtists from './curated_artists';


export default function TreatmentQuestions(props) {

    const [type, setType] = useState(false)

    const {treatmentquestions, fetchTreatmentQuestions, 
            curatedworksamples, fetchCuratedWorkSamples,
            curatedArtists, fetchCuratedArtists} = props


    useEffect(() => {
        window.scrollTo(0, 0)
        setType(window.location.pathname.split("/")[2])
      }, [])

  

      useEffect(() => {
        if (type) {
            fetchTreatmentQuestions(type)
            fetchCuratedWorkSamples(type)
            fetchCuratedArtists(type)
        }
        
        }, [type])

    return (
        <div className="">
            
   
            <div className='title6 l no-pad-top'>
            {type && type === "ombre" ? "Ombré Brows" : 
            type && type === "microblading" ? "Microblading / Nanoblading" : 
            type && type === "combo" ? "Combo Brows" : 
            type && type === "lips" ? "Lip Blush" : 
            type && type === "eyeliner" ? "Lash Enhancement / Eyeliner" : 
            null}
            </div>
        <br/>
            <div className='photo-wrap'>
            {curatedworksamples && Object.keys(curatedworksamples).map((key) => {
                 
                return (
            <div className='photo-container'>
        
                    <img className="main-work-sample" src={`${curatedworksamples[key].url}`} alt={`lip blush, microblading, ombre brows, permanent makeup, cosmetics, Easton, Allentown, Stroudsburg, Bethlehem, Scranton, NYC, New York`} ></img>
                    </div>

                )
            })}</div>
                    <div className='title5 desktop-not'>
                         <div className='pic-caption'>
                            All work samples done at COSMETINK® </div>
                         </div>


            <div className='l'>
        
                <h1>
                    Who Can I Book?
                </h1>
            
      
            </div>
    
            <CuratedArtists currentArtists={curatedArtists} type={type}/>


             {treatmentquestions && Object.keys(treatmentquestions).map((key) => {
                const tq = treatmentquestions[key]
                return (
                    !tq.link ?
                    <>
                    <h1>
                        {tq.question}
                    </h1>
                    <div className='font-14'>
                        <br/>
                    <div className='yes-box'>           
                    <i class="fa-regular fa-face-thinking"></i>
                                <p className='smaller2'>
                        {tq.answer}
                    </p>
                    </div>         
                    </div>
                    </> : 
                    <>
                    <h1>
                        {tq.question}
                    </h1>
                    <p className='smaller'>
                    <Link className='email-link' to={`${tq.linkto}`}>{tq.answer} <i class="fa-solid fa-chevron-right"></i></Link>
                    </p>
                
                    </>
                 )
            }) }


    
        
          </div>
        
            

      
      )}