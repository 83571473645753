import React, { useState, useEffect, Suspense } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import MiniArtistPage from './mini_artist_page';

export default function CuratedArtists(props) {

    const {currentArtists, type} = props

    const [selectedArtist, setSelectedArtist] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

    function selectArtist(artist) {
        selectedArtist ? setSelectedArtist(null) : setSelectedArtist(artist)
    }


    return (
        <div className="curated-artists">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top Permanent Makeup Artists in the Lehigh Valley</title>
                <link rel="canonical" href="https://www.cosmetink.com/" />
                <meta name="description" content="Lehigh Valley's Award-Winning, Premier and First Targeted Permanent Makeup Studio & Academy - for all ages, skin types, and colors.  We specialize in nano brows, microblading, ombre brows, and lip blush.  We're located in the Lehigh Valley, Pennsylvania.  Conveniently located between New York City and Philadelphia" />
                <meta name="keywords" content="eyebrows permanent makeup cosmetics lehigh valley, pennsylvania, lehigh valley pa, lehigh valley pennsylvania, tattoo makeup, cosmetic ink, permanent makeup, ombre powder brows, microblading, lip blush, semi-permanent makeup" />
            </Helmet>   
       

            {type && type === "microblading" ? 

            <>
                   <div className='title6 l'>
                    Nanoblading / Nano Brows
                   </div> 


                    <p className='l'>
                    Click To Explore Our Artists, Their Work, and Pricing
                    </p>

                <div className=''>
       
                <div className='inside-curated-artists'>

                { currentArtists && Object.keys(currentArtists).map((key) => {
                    if (currentArtists[key].nanoblading)
                    return (
                         <>
                        <div onClick={() => selectArtist(currentArtists[key])} className='artist-box4'>
                       
                        <img src={currentArtists[key].imgURL} className={'artist-img' }/>
                        <div className='artist-name3'>
                            {currentArtists[key].name} <span className='artist-title2'>{currentArtists[key].title}
                            </span>
                      
               
                        </div>
                        <div>
                        
                        </div>
                        </div>
                        </>
                    )
                })
              }
       
              </div>
              </div> 
            </>
         
            
            
            
            : null }

            {type && type === "microblading" ? 

            <div className='title6 l'>
            Microblading
            </div> : null 

            }

            <p className='l'>
                    Click To Explore Our Artists, Their Work, and Pricing
                    </p>
            
            
            <div className=''>
            
                <div className='inside-curated-artists'>

                { currentArtists && Object.keys(currentArtists).map((key) => {
                    return (
                         <>
                        <div onClick={() => selectArtist(currentArtists[key])} className='artist-box4'>
                       
                        <img src={currentArtists[key].imgURL} className={'artist-img' }/>
                        <div className='artist-name3'>
                            {currentArtists[key].name} <span className='artist-title2'>{currentArtists[key].title}
                            </span>
                        </div>
                        <div>
                        
                        </div>
                        </div>
                        </>
                    )
                })
              }
       
              </div>
              </div> 

              {selectedArtist ? 
              <MiniArtistPage selectedArtist={selectedArtist} selectArtist={selectArtist} /> : null }

        </div>
        
            

      
      )}