import { connect } from 'react-redux';
import ArtistSidebar from './artist_sidebar';
import {fetchCurrentArtists} from '../../actions/current_artists_actions'

const mapStateToProps = (state) => {
  return {
    currentArtists: state.currentArtists.currentArtists
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCurrentArtists: () => dispatch(fetchCurrentArtists()),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtistSidebar);