import { combineReducers } from 'redux';
import currentArtists from './current_artists_reducer';
import policies from './policies_reducer';
import precare from './precare_reducer';
import worksamples from './work_samples_reducer';
import treatmentquestions from './treatment_questions_reducer'

const RootReducer = combineReducers({
  currentArtists,
  policies,
  precare,
  worksamples,
  treatmentquestions
});

export default RootReducer;