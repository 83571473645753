import React, { useState, useEffect, Suspense } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'


export default function Treatments(props) {


    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

  

      useEffect(() => {

        
        }, [])

    return (
        <div className="main-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top Permanent Makeup Artists in the Lehigh Valley</title>
                <link rel="canonical" href="https://www.cosmetink.com/" />
                <meta name="description" content="Lehigh Valley's Award-Winning, Premier and First Targeted Permanent Makeup Studio & Academy - for all ages, skin types, and colors.  We specialize in nano brows, microblading, ombre brows, and lip blush.  We're located in the Lehigh Valley, Pennsylvania.  Conveniently located between New York City and Philadelphia" />
                <meta name="keywords" content="eyebrows permanent makeup cosmetics lehigh valley, pennsylvania, lehigh valley pa, lehigh valley pennsylvania, tattoo makeup, cosmetic ink, permanent makeup, ombre powder brows, microblading, lip blush, semi-permanent makeup" />
            </Helmet>    
   
 
<div className='title6'>
Our Services
</div>
<div className='smaller c'>Click to Learn More</div>

<div className='service-row'>

  <Link to="/treatments/microblading">
  <div className='service-container'>
    <div className='service-container-overlay'></div>
    <div className='service-name'>Microblading /<br/>Nanoblading<br/>Hairstrokes</div>

    <video className='service' alt="microblading nanobrows nanoblading cosmetink" loop muted defaultMuted autoPlay controls = '' playsInline src='https://cosworksamples.s3.us-east-2.amazonaws.com/video+2.mp4' poster='https://cosworksamples.s3.us-east-2.amazonaws.com/microposter.jpg' />

  </div>
  </Link>

  <Link to="/treatments/ombre">
  <div className='service-container'>
    <div className='service-container-overlay'></div>
    <div className='service-name'>Ombré Brows</div>
    <video alt="microblading nanobrows nanoblading cosmetink" loop muted defaultMuted autoPlay controls = '' playsInline src='https://cosworksamples.s3.us-east-2.amazonaws.com/video+4.mp4' poster='https://cosworksamples.s3.us-east-2.amazonaws.com/ombreposter.jpg' />

  </div>
  </Link>

  <Link to="/treatments/combo">
  <div className='service-container'>
    <div className='service-container-overlay'></div>
    <div className='service-name'>Combo Brows</div>
    <video className='rotate-180' alt="microblading nanobrows nanoblading cosmetink" loop muted defaultMuted autoPlay controls = '' playsInline src='https://cosworksamples.s3.us-east-2.amazonaws.com/video+3.mp4' poster='https://cosworksamples.s3.us-east-2.amazonaws.com/comboposter.jpg' />

  </div>
  </Link>


  <Link to="/treatments/lips">
  <div className='service-container'>
    <div className='service-container-overlay'></div>
    <div className='service-name'>Lip Blush</div>
    <video className='rotate-180' alt="microblading nanobrows nanoblading cosmetink" loop muted defaultMuted autoPlay controls = '' playsInline src='https://cosworksamples.s3.us-east-2.amazonaws.com/lipclickpmu12.mp4' poster='https://cosworksamples.s3.us-east-2.amazonaws.com/IMG_4386.JPG' />

  </div>
  </Link>

  <Link to="/treatments/eyeliner">
  <div className='service-container eyeliner'>
    <div className='service-container-overlay eye'></div>
    <div className='service-name eye'>Eyeliner</div>
    <img src ='https://cosworksamples.s3.us-east-2.amazonaws.com/eyelinermain.jpeg' />
    
  </div>
  </Link>
</div>

                    
        </div>
        
            

      
      )}


