import { RECEIVE_CURRENT_ARTISTS, RECEIVE_CURATED_ARTISTS } from '../actions/current_artists_actions';

const CurrentArtistsReducer = (state = { currentArtists: undefined, curatedArtists: undefined}, action) => {
Object.freeze(state);
let newState = Object.assign({}, state);
switch(action.type) {
 case RECEIVE_CURRENT_ARTISTS:
   newState.currentArtists = action.currentartists.data;
   return newState;
case RECEIVE_CURATED_ARTISTS:
    newState.curatedArtists = action.curatedartists.data;
    return newState;
 default:
   return state;
}
};

export default CurrentArtistsReducer;