import React from 'react';
import {createRoot} from 'react-dom/client';
import Root from './components/root';

import configureStore from './store/store';


document.addEventListener('DOMContentLoaded', () => {
  let store;

    store = configureStore({});
  
  const root = document.getElementById('root');

  createRoot(root).render(<Root store={store} /> );
});