import React, { useState, useEffect, Suspense } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import ArtistPage from './artist_page';

export default function ArtistSidebar(props) {

    const {fetchCurrentArtists, currentArtists, chooseArtist, setArtists} = props
  
    const [selectedArtist, setSelectedArtist] = useState("")
    const [currentFlip, setCurrentFlip] = useState(0)


    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

  

      useEffect(() => {
        fetchCurrentArtists() 
        
        }, [])

    return (
        <div className="artist-sidebar slide-in-bottom">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top Permanent Makeup Artists in the Lehigh Valley</title>
                <link rel="canonical" href="https://www.cosmetink.com/" />
                <meta name="description" content="Lehigh Valley's Award-Winning, Premier and First Targeted Permanent Makeup Studio & Academy - for all ages, skin types, and colors.  We specialize in nano brows, microblading, ombre brows, and lip blush.  We're located in the Lehigh Valley, Pennsylvania.  Conveniently located between New York City and Philadelphia" />
                <meta name="keywords" content="eyebrows permanent makeup cosmetics lehigh valley, pennsylvania, lehigh valley pa, lehigh valley pennsylvania, tattoo makeup, cosmetic ink, permanent makeup, ombre powder brows, microblading, lip blush, semi-permanent makeup" />
            </Helmet>   
              <i onClick={() => setArtists(false)} class="fa-sharp fa-solid fa-circle-xmark"></i>
              
              
              { selectedArtist && 
              <ArtistPage selectedArtist={selectedArtist}/>
              }



             
            <div className='inside-artist-sidebar'>
  

            <br/>   <br/> <br/>
                {!selectedArtist &&
                <div className='title6'>
                    Click To Explore Our Artists, <br/>Their Work, and Pricing
                </div>
                }
                <br/>

                { currentArtists && Object.keys(currentArtists).map((key) => {
                    return (
                        !selectedArtist && <>
                        <div onClick={() => chooseArtist(currentArtists[key])} className='artist-box'>
                       
                        <img src={currentArtists[key].imgURL} className={'artist-img' }/>
                        <div className='artist-name'>
                            {currentArtists[key].name} <span className='artist-title'>{currentArtists[key].title}</span>
               
                            <div className='artist-title4 purp'>

                            {currentArtists[key].techniques}
                            </div>
                        </div>
                        </div>
        
               
                        </>
                    )
                })
              }
              </div>
        </div>
        
            

      
      )}