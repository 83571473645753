import { RECEIVE_POLICIES } from '../actions/policies_actions';

const PoliciesReducer = (state = { policies: undefined}, action) => {
Object.freeze(state);
let newState = Object.assign({}, state);
switch(action.type) {
 case RECEIVE_POLICIES:
   newState.policies = action.policies.data;
   return newState;
 default:
   return state;
}
};

export default PoliciesReducer;