import { RECEIVE_TREATMENT_QUESTIONS } from '../actions/treatment_questions_actions';

const TreatmentQuestionsReducer = (state = { treatmentquestions: undefined}, action) => {
Object.freeze(state);
let newState = Object.assign({}, state);
switch(action.type) {
 case RECEIVE_TREATMENT_QUESTIONS:
   newState.treatmentquestions = action.treatmentquestions.data;
   return newState;
 default:
   return state;
}
};

export default TreatmentQuestionsReducer;