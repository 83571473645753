import * as Util from '../util/precare_util'

export const RECEIVE_PRECARE = "RECEIVE_PRECARE"

export const receivePrecare = precare => ({
    type: RECEIVE_PRECARE,
    precare
})

export const fetchPrecare = () => dispatch => {
    Util.fetchPrecare().then(precare => {
        dispatch(receivePrecare(precare))
    })
}