import React, { useState, useEffect, Suspense } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'


export default function Process(props) {


    useEffect(() => {
        window.scrollTo(0, 0)

      }, [])

  

      useEffect(() => {

        
        }, [])

    return (
        <div className="add-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top Permanent Makeup Artists in the Lehigh Valley</title>
                <link rel="canonical" href="https://www.cosmetink.com/" />
                <meta name="description" content="Lehigh Valley's Award-Winning, Premier and First Targeted Permanent Makeup Studio & Academy - for all ages, skin types, and colors.  We specialize in nano brows, microblading, ombre brows, and lip blush.  We're located in the Lehigh Valley, Pennsylvania.  Conveniently located between New York City and Philadelphia" />
                <meta name="keywords" content="eyebrows permanent makeup cosmetics lehigh valley, pennsylvania, lehigh valley pa, lehigh valley pennsylvania, tattoo makeup, cosmetic ink, permanent makeup, ombre powder brows, microblading, lip blush, semi-permanent makeup" />
            </Helmet>          
            <div className='title6'>
                The Process
            </div>
          


             <h1 className='title6'>
                What to Expect During Your Appointment?
            </h1>     
            <br/>
            <div className='l c'>
            <img src="https://cosworksamples.s3.us-east-2.amazonaws.com/procedure-room.jpg" alt="COSMETINK Procedure Room" className='procedure-room'></img> 
            </div>

            <div className='r'>
             <h1>
                STEP 1
            </h1>
            <p className='smaller'>
                CONSULTATION (15-20 min)
            </p>
            <p>
            Welcome to Cosmetink! This is where we’ll have you fill out the consent form and get to know you and your lifestyle. We’ll examine your concern whether it be brows, lips, or eyes, and ask you a few questions about your daily routine. You’re welcome to to bring any inspiration photos you may have and we’ll discuss your shape preferences and pigment color. As you can see, consultations are part of the procedure. We do not do them in a separate appointment. Although it is extremely rare, if for some reason, we cannot perform the procedure after your consultation, we will let you know, or for some reason, you do not want to, we totally understand!
            </p>

            <h1>
            STEP 2
            </h1>
            <p className='smaller'>
                BROW/LIP/EYE MAPPING (20-30 min)
            </p>
            <p>
            Using our measuring tools, we’ll create the customized yet perfectly suited outline that’s best proportioned to your facial features, whether it is brows, lips, or eyes! At this point we may ask you to smile, frown, talk and laugh so we can see how your mimic muscles move. We’ll both review the design and make any adjustments necessary. Once the design is approved we will begin!
            </p>

            <h1>
            STEP 3
            </h1>
            <p className='smaller'>
            PROCEDURE (1.5-2.5 HOURS)
            </p>
            <p>
            You're ready to begin your transformation! For your comfort you will be numbed throughout the procedure. Please advise you will be laying down for the entire procedure.
            </p>

            <h1>
            AFTERCARE
            </h1>
            <p>
            We will review after care instructions with you and provide you with an aftercare kit that includes everything you’ll need for the initial healing period.<br/><br/>
            Brow heal in about 10-14 days and Lips & Eyeliners heal in about 7 days. <br/>
            <br/>During this time, you will want to avoid direct sun exposure, swimming, and excess sweating, so please plan vacations and life events accordingly.  Other than that, during the healing phase, you will be able to go to work and about your business as usual.  In fact, a lot of our clients go to work or out with friends immediately following the session; while some redness or swelling may occur, it is usually very minimal.  Following the PRE-CARE information will prevent most causes of excess redness and swelling immediately following your session.<br/>
            <br/>It’s important that you follow all the aftercare instructions to achieve the best results and retention for your brows. We wouldn’t want to waste all that time and TLC we just invested into them!  


            </p>
            </div>

        </div>
        
            

      
      )}