import { connect } from 'react-redux';
import Policies from './policies';
import {fetchPolicies} from '../../actions/policies_actions'

const mapStateToProps = (state) => {
  return {
    policies: state.policies.policies
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPolicies: () => dispatch(fetchPolicies()),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Policies);