import React, { useState, useEffect, Suspense } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import WorkSamples from './work_samples_container';

export default function ArtistPage(props) {

    const {selectedArtist, chooseArtist, artistBio, openBio} = props
    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);



    const onScroll = (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
        setScrolling(e.target.documentElement.scrollTop > scrollTop);
      }

      useEffect(() => {
        window.addEventListener('scroll', onScroll);
      },[]);

  


    return (
            <div className='artist-page slide-in-bottom'>
                <div className=''>

                <i onClick={() => chooseArtist("")} class="fa-sharp fa-solid fa-circle-xmark"></i>
                <div className='artist-box2'>
                    <div className='artist-box-overlay'></div>
                        <img src={selectedArtist.imgURL} className='artist-img2' />
                        <video loop muted autoPlay controls='' playsInline  src={selectedArtist.videoURL} className='artist-img'/> 
                            <div className='artist-name2'>
                                {selectedArtist.name}<span className='artist-title2'>{selectedArtist.fullTitle || selectedArtist.title}</span>
                            </div>
                            
                </div>
            
                <div className='gradient-underline'></div>
  
            <br/>
                <div className='title6 l'>
               Samples of {selectedArtist.name}'s Work
                </div>
                <div className='artist-bio'>
                Scroll to See {selectedArtist.name}'s work <i class="fa-duotone fa-right-left-large"></i>
                </div>

                <WorkSamples artist_id={selectedArtist._id} />
                <br/>
                <div className='title6 l'>
               Techniques Performed
                </div>
                <div className='artist-bio'>
                {selectedArtist.techniques}
                </div>
                <br/>
                <div className='title6 l'>
                Pricing
                </div>
                <div className='artist-bio bold'>

                <div>
                First Session Brows<span className='price'>
                {selectedArtist.browPrice} </span>
                </div>

                <div>
                First Session Lips<span className='price'>{selectedArtist.lipPrice} </span>
                </div>

                {selectedArtist.eyelinerPrice ? 
                <div>
                First Session Lash Enhancement
                <span className='price'>{selectedArtist.eyelinerPrice}</span> 
                </div> : null }

                <div><br/>Mandatory Perfecting Session<span className='price'>$150</span>
                </div>

                {selectedArtist.browAnnual ? 
                <div>
                    <br/>
                Annual Refresh Brows (12-32 months)
                <span className='price'>{selectedArtist.browAnnual}</span>
                </div> : null}

                {selectedArtist.lipAnnual ? 
                <div>
                Annual Refresh Lips (12-32 months)
                <span className='price'>{selectedArtist.lipAnnual}</span>
                </div>: null } 

                {selectedArtist.eyelinerAnnual ? 
                <div>
                Annual Refresh Lash Enhancement (12-32 months)
                <span className='price'>{selectedArtist.eyelinerAnnual}</span>
                </div>: null } 

                </div>
              
                <br/>
                <div className='title6 l'>
               Get To Know {selectedArtist.name}
                </div>

                <div className={artistBio ? 'artist-bio l' : 'artist-bio-closed'}>
                    {selectedArtist.bio}
                </div>
                <div onClick={openBio} className='read-more l'>{artistBio ? `Read Less` : `Read More `}</div>

                <br/><br/><br/>   <br/><br/><br/>   <br/><br/><br/>
                </div>
            </div>
              

          
 
            

      
      )}