import React, { useState, useEffect, Suspense } from 'react';
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import TreatmentQuestions from './treatmentquestions_container';


export default function TreatmentType(props) {

    const [type, setType] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0)
        setType(window.location.pathname.split("/")[2])
      }, [])

  

      useEffect(() => {
        
        }, [type])

    return (
        <div className="add-page">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top Permanent Makeup Artists in the Lehigh Valley</title>
                <link rel="canonical" href="https://www.cosmetink.com/" />
                <meta name="description" content="Lehigh Valley's Award-Winning, Premier and First Targeted Permanent Makeup Studio & Academy - for all ages, skin types, and colors.  We specialize in nano brows, microblading, ombre brows, and lip blush.  We're located in the Lehigh Valley, Pennsylvania.  Conveniently located between New York City and Philadelphia" />
                <meta name="keywords" content="eyebrows permanent makeup cosmetics lehigh valley, pennsylvania, lehigh valley pa, lehigh valley pennsylvania, tattoo makeup, cosmetic ink, permanent makeup, ombre powder brows, microblading, lip blush, semi-permanent makeup" />
            </Helmet>    
   
            <div className='title6 l'>
     
            </div>

            <TreatmentQuestions/>    
        
            

                    
        </div>
        
            

      
      )}