import { connect } from 'react-redux';
import Precare from './precare';
import {fetchPrecare} from '../../actions/precare_actions'

const mapStateToProps = (state) => {
  return {
    precare: state.precare.precare
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPrecare: () => dispatch(fetchPrecare()),

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Precare);