import { RECEIVE_PRECARE } from '../actions/precare_actions';

const PrecareReducer = (state = { precare: undefined}, action) => {
Object.freeze(state);
let newState = Object.assign({}, state);
switch(action.type) {
 case RECEIVE_PRECARE:
   newState.precare = action.precare.data;
   return newState;
 default:
   return state;
}
};

export default PrecareReducer;