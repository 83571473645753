import * as Util from '../util/currentartists_util'

export const RECEIVE_CURRENT_ARTISTS = "RECEIVE_CURRENT_ARTISTS"

export const RECEIVE_CURATED_ARTISTS = "RECEIVE_CURATED_ARTISTS"

export const receiveCurrentArtists = currentartists => ({
    type: RECEIVE_CURRENT_ARTISTS,
    currentartists
})

export const receiveCuratedArtists = curatedartists => ({
    type: RECEIVE_CURATED_ARTISTS,
    curatedartists
})


export const fetchCurrentArtists = () => dispatch => {
    Util.fetchCurrentArtists().then(currentartists => {
        dispatch(receiveCurrentArtists(currentartists))
    })
}

export const fetchCuratedArtists = (type) => dispatch => {
    Util.fetchCuratedArtists(type).then(curatedartists => {
        dispatch(receiveCuratedArtists(curatedartists))
    })
}