import React, {Suspense, lazy} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';



import LandingPage from './main/landing'
import Navbar from './navbar/navbar';
import Precare from './main/precare_container';
import Policies from './main/policies_container';
import Process from './main/process';
import Financing from './main/financing';
import Contact from './main/contact';
import AboutUs from './main/about_us';
import Treatments from './main/treatments';
import TreatmentType from './main/treatment_type';


const App = () => (
  <div className="main">
    <Suspense fallback={<div>
    </div>}>
        <Navbar/>
         
        <Routes>
            <Route exact path="/" element={<LandingPage/>} />
            <Route exact path="/preparation" element={<Precare/>} />
            <Route exact path="/policies" element={<Policies/>} />
            <Route exact path="/process" element={<Process/>} />
            <Route exact path="/financing" element={<Financing/>} />
            <Route exact path="/contact" element={<Contact/>} />
            <Route exact path="/aboutus" element={<AboutUs/>} />
            <Route exact path="/treatments/:type" element={<TreatmentType/>} />
            <Route exact path="/treatments" element={<Treatments/>} />
        </Routes>

    </Suspense>
  </div>
);

export default App;