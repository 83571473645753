import * as Util from '../util/treatment_questions_util'

export const RECEIVE_TREATMENT_QUESTIONS = "RECEIVE_TREATMENT_QUESTIONS"

export const receiveTreatmentQuestions = treatmentquestions => ({
    type: RECEIVE_TREATMENT_QUESTIONS,
    treatmentquestions
})

export const fetchTreatmentQuestions = (type) => dispatch => {
    Util.fetchTreatmentQuestions(type).then(treatmentquestions => {
        dispatch(receiveTreatmentQuestions(treatmentquestions))
    })
}