import React, { useState, useEffect, Suspense } from 'react';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import AboutUs from './about_us';


export default function LandingPage(props) {

  const [aboutPage, setAboutPage] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  function aboutUs() {
    aboutPage ? setAboutPage(false) : setAboutPage(true)
}






  return (
    <div className="main-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Permanent Makeup Lehigh Valley, Pennsylvania - Nano Brows - Microblading - Ombre Brows - Lip Blush - Eyeliner - Eyebrows</title>
        <link rel="canonical" href="https://www.cosmetink.com/" />
        <meta name="description" content="Lehigh Valley's Award-Winning, Premier and First Targeted Permanent Makeup Studio & Academy - for all ages, skin types, and colors.  We specialize in nano brows, microblading, ombre brows, and lip blush.  We're located in the Lehigh Valley, Pennsylvania.  Conveniently located between New York City and Philadelphia" />
        <meta name="keywords" content="eyebrows permanent makeup cosmetics lehigh valley, pennsylvania, lehigh valley pa, lehigh valley pennsylvania, tattoo makeup, cosmetic ink, permanent makeup, ombre powder brows, microblading, lip blush, semi-permanent makeup" />
      </Helmet>


      <a alt="COSMETINK Experience" href="https://cosmetinkvideos.s3.us-east-2.amazonaws.com/cosmetinkXP.mp4">
        <div className='main-video-container'>
          <div className='best-of'>
            <img alt="best of the lehigh valley" src="https://cosmetinklanding.s3.us-east-2.amazonaws.com/lvbestof2024.PNG" />
          </div>


          <div className='video-container'>
            {window.innerWidth < 801 ?
            <video height="auto" fetchpriority="high" loading="" loop muted autoPlay controls='' playsInline className="desktop-not work-sample fade-in" src={`https://cosworksamples.s3.us-east-2.amazonaws.com/mobilecosvid11.mp4`} poster={`https://cosworksamples.s3.us-east-2.amazonaws.com/covervidmobile.jpg`} />
            : null}
            :
            {window.innerWidth > 800 ? 
            <video fetchpriority="high" loading="" loop muted autoPlay controls='' playsInline className="mobile-no fade-in destop-vid" src={`https://cosmetinklanding.s3.us-east-2.amazonaws.com/desktop-vidcosmetink.mp4`} poster={`https://cosmetinklanding.s3.us-east-2.amazonaws.com/desktop-cosvidposter.jpg`} /> : null }
            
          </div>

          <div className='video-overlay'>
          </div>
          <div className='title4 wht c studio'>
            <span className='moving-grad4'>INK</span>SPIRED BY YOU<span className='reg-tm'>
            ™ </span>
            <div className='smaller'>
              Our Award-Winning Team transformed the brows of these 5 women<br />

              <div className='internal-button'>
                <a href="https://cosmetinkvideos.s3.us-east-2.amazonaws.com/cosmetinkXP.mp4" className='internal-link'>Watch Now</a>
              </div>
            </div>
          </div>
        </div>
      </a>
<br/>



      <div className='title6'>
      Watch Each Unique Transformation from the Campaign*
      </div>
      <br/>

      <div className='model-video-row'>
        <a href="https://cosmetinkvideos.s3.us-east-2.amazonaws.com/Model1.mp4" className='main-model-video-container' alt="permanent makeup transformation cosmetink ombre brows">

          <div className='model-video-container'>
            <div className='model-video-overlay'></div>
            <i class="fa-solid fa-circle-play"></i>
            <div className='model-name'>
              Mona
            </div>
            <img alt="COSMETINK Permanent Makeup Brow Transformation" className='model-img' src="https://cosmetinklanding.s3.us-east-2.amazonaws.com/Mona.jpg" />
          </div>
        </a>
        <a href="https://cosmetinkvideos.s3.us-east-2.amazonaws.com/Model2.mp4" className='main-model-video-container' alt="permanent makeup transformation cosmetink ombre brows">
          <div className='model-video-container'>
            <div className='model-video-overlay'></div>
            <i class="fa-solid fa-circle-play"></i>
            <div className='model-name'>
              Samone
            </div>
            <img alt="COSMETINK Permanent Makeup Brow Transformation" className='model-img' src="https://cosmetinklanding.s3.us-east-2.amazonaws.com/Samone.jpg" />
          </div>
        </a>
        <a href="https://cosmetinkvideos.s3.us-east-2.amazonaws.com/Model3.mp4" className='main-model-video-container' alt="permanent makeup transformation cosmetink ombre brows">
          <div className='model-video-container'>
            <div className='model-video-overlay'></div>
            <i class="fa-solid fa-circle-play"></i>
            <div className='model-name'>
              Britney
            </div>
            <img alt="COSMETINK Permanent Makeup Brow Transformation"  className='model-img' src="https://cosmetinklanding.s3.us-east-2.amazonaws.com/Britney.jpg" />
          </div>
        </a>
        <a href="https://cosmetinkvideos.s3.us-east-2.amazonaws.com/Model5.mp4" className='main-model-video-container' alt="permanent makeup transformation cosmetink ombre brows">
          <div className='model-video-container'>
            <div className='model-video-overlay'></div>
            <i class="fa-solid fa-circle-play"></i>
            <div className='model-name'>
              Megan
            </div>
            <img alt="COSMETINK Permanent Makeup Brow Transformation" className='model-img' src="https://cosmetinklanding.s3.us-east-2.amazonaws.com/Meg.jpg" />
          </div>
        </a>
        <a href="https://cosmetinkvideos.s3.us-east-2.amazonaws.com/Model4.mp4" className='main-model-video-container' alt="permanent makeup transformation cosmetink ombre brows">
          <div className='model-video-container'>
            <div className='model-video-overlay'></div>
            <i class="fa-solid fa-circle-play"></i>
            <div className='model-name'>
              Lizzy
            </div>
            <img alt="COSMETINK Permanent Makeup Brow Transformation"  className='model-img' src="https://cosmetinklanding.s3.us-east-2.amazonaws.com/Lizzy.jpg" />
          </div>
        </a>
      </div>

      <div className='smaller c'>
          *All models wear brows done by artists at COSMETINK®
      </div>
      <br/>
      <div className='title6 c'>
      Our Services
 
      </div>
      <div className='c smaller'>Click to Learn More</div>

      <div className='service-row'>

        <Link to="/treatments/microblading">
        <div className='service-container'>
          <div className='service-container-overlay'></div>
          <div className='service-name'>Microblading /<br/>Nanoblading</div>

          <video alt="microblading nanobrows nanoblading cosmetink" loop muted defaultMuted autoPlay controls = '' playsInline src='https://cosworksamples.s3.us-east-2.amazonaws.com/video+2.mp4' poster='https://cosworksamples.s3.us-east-2.amazonaws.com/microposter.jpg' />
 
        </div>
        </Link>

        <Link to="/treatments/ombre">
        <div className='service-container'>
          <div className='service-container-overlay'></div>
          <div className='service-name'>Ombré Brows</div>
          <video alt="microblading nanobrows nanoblading cosmetink" loop muted defaultMuted autoPlay controls = '' playsInline src='https://cosworksamples.s3.us-east-2.amazonaws.com/video+4.mp4' poster='https://cosworksamples.s3.us-east-2.amazonaws.com/ombreposter.jpg' />
 
        </div>
        </Link>

        <Link to="/treatments/combo">
        <div className='service-container'>
          <div className='service-container-overlay'></div>
          <div className='service-name'>Combo Brows</div>
          <video className='rotate-180' alt="microblading nanobrows nanoblading cosmetink" loop muted defaultMuted autoPlay controls = '' playsInline src='https://cosworksamples.s3.us-east-2.amazonaws.com/video+3.mp4' poster='https://cosworksamples.s3.us-east-2.amazonaws.com/comboposter.jpg' />
 
        </div>
        </Link>


        <Link to="/treatments/lips">
        <div className='service-container'>
          <div className='service-container-overlay'></div>
          <div className='service-name'>Lip Blush</div>
          <video className='rotate-180' alt="microblading nanobrows nanoblading cosmetink" loop muted defaultMuted autoPlay controls = '' playsInline src='https://cosworksamples.s3.us-east-2.amazonaws.com/lipclickpmu12.mp4' poster='https://cosworksamples.s3.us-east-2.amazonaws.com/IMG_4386.JPG' />
 
        </div>
        </Link>

        <Link to="/treatments/eyeliner">
        <div className='service-container eyeliner'>
          <div className='service-container-overlay eye'></div>
          <div className='service-name eye'>Eyeliner</div>
          <img src ='https://cosworksamples.s3.us-east-2.amazonaws.com/eyelinermain.jpeg' />
          
        </div>
        </Link>
      </div>
<br/><br/><br/>






      



<div className='left-container'>
      <div className='landing-box'>

            <div className='title6'>
                30 Second Financing Approval
            </div>
        

                <br/>

            <div className='financing c'>
            <i class="fa-duotone fa-credit-card"></i>
             <h1 className='smaller2'>
                Apply Now
            </h1>
            <p className='financing c'>
                Payments as low as $30/month
            <br/>
            <p className='smaller c'>
            0% APR Options Available<br/>
            No Hard Credit Check<br/>
            </p>
            </p>

            <a className='email-link wht' href="mailto:cosmetinkbeauty@gmail.com?subject=30 Second Credit Application&body=Please send me a credit application to see how much I'm approved for.">
            Get Your 30 Second Application <br/>Email Us <i class="fa-sharp fa-light fa-up-right-from-square"></i>
            </a>
            </div>

            </div>
            </div>
        


            <div className='right-container'>
       

            <div className='megcontainer'>
   
   <img alt="best of the lehigh valley" className='megbanner' src="https://cosworksamples.s3.us-east-2.amazonaws.com/campaign3.jpg"/>
   
</div>


<div className='title5 '>
<div className='pic-caption'>
 This photo from our campaign shows our models, Meg & Britney, wearing Ombré Brows done at COSMETINK®, directly after the procedure.   Healed results will be softer by 20-40% and more natural.
</div>
</div>


            </div>
<br/>

            <div className='left-container'>

            <div className=''>
            {window.innerWidth < 801 ?
        <img className='megbanner4' alt="COSMETINK Procedure Room" src="https://cosmetinklanding.s3.us-east-2.amazonaws.com/roombanner.jpg" /> : 
        <img className='megbanner4' alt="COSMETINK Procedure Room" src="https://cosworksamples.s3.us-east-2.amazonaws.com/procedureroom.jpg" /> 
            }
      </div>
  
      <div className='title5 '>
      <div className='pic-caption'>
          Procedure room at COSMETINK®
        </div>
      </div>
      
   
        </div>


        <div className='right-container'>
         <Link onClick={setAboutPage}>
            <div className='sibling c'>
              <div className='sibling-overlay'></div>
              <img className="sibling-duo" src="https://cosmetinklanding.s3.us-east-2.amazonaws.com/siblingduo.jpg">
              </img>
              <div className='sibling-duo overlay-text'>
              <div className='internal-button'>
                <a href="https://cosmetinkvideos.s3.us-east-2.amazonaws.com/cosmetinkXP.mp4" className='internal-link'>About Us</a>
              </div>
                Learn about the Brother-Sister Duo behind it all
              </div>
            </div>
            </Link>

      </div>
      <br/>


            <div className='footer'>
              <div className='left'>

              </div>
              <div className='right'>
              <div className='title6'>
            <i class="fa-solid fa-map-pin"></i>&nbsp;&nbsp; Lehigh Valley, Pennsylvania
            <p className='smaller c'>
            Conveniently located 90 minutes from <br/>Philadelphia & NYC
            </p>
            </div>

            
        


            <div className='financing c'>
         
            <a className='email-link' href="https://www.google.com/maps/dir//3859+Nazareth+Pike+Unit+103,+Bethlehem,+PA+18020/@40.673803,-75.4283102,12z/data=!4m6!4m5!1m0!1m2!1m1!1s0x89c443557b11e64d:0x601c04db1170f5b6!3e0">
            Get Directions <i class="fa-sharp fa-light fa-up-right-from-square"></i>
            </a>
            <br/>

            <p className='c'>
            3859 Nazareth Pike<br/>
            Bethlehem, PA 18020<br/>
            <a className='email-link' href="mailto:cosmetinkbeauty@gmail.com">
            cosmetinkbeauty@gmail.com 
            </a>
            </p>

    
            <br/><br/>
            <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d773617.6840724369!2d-75.23010343128904!3d40.76325467428503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c443557b11e64d%3A0x601c04db1170f5b6!2sCOSMETINK%C2%AE%20Permanent%20Cosmetics%20Studio%20%26%20Academy!5e0!3m2!1sen!2sus!4v1657835141207!5m2!1sen!2sus" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

              </div>
              <br/>

            <div className='financing left-words-2 c'>
              Copyright © 2024, COSMETINK LLC
            </div>              
            
            </div>

            {aboutPage ? 
            <AboutUs aboutUs={aboutUs} /> : null }


    </div>

  )
}