import { connect } from 'react-redux';
import TreatmentQuestions from './treatmentquestions';
import {fetchTreatmentQuestions} from '../../actions/treatment_questions_actions';
import { fetchCuratedWorkSamples } from '../../actions/work_samples_actions';
import { fetchCuratedArtists } from '../../actions/current_artists_actions';

const mapStateToProps = (state) => {
  return {
    treatmentquestions: state.treatmentquestions.treatmentquestions,
    curatedworksamples: state.worksamples.curatedworksamples,
    curatedArtists: state.currentArtists.curatedArtists
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTreatmentQuestions: (type) => dispatch(fetchTreatmentQuestions(type)),
    fetchCuratedWorkSamples: (type) => dispatch(fetchCuratedWorkSamples(type)),
    fetchCuratedArtists: (type) => dispatch(fetchCuratedArtists(type))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TreatmentQuestions);