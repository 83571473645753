import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet' 
import ArtistSidebar from '../main/artist_sidebar_container';
import ArtistPage from '../main/artist_page';
import AboutUs from '../main/about_us';


export default function Navbar(props)  {

    const [artists, setArtists] = useState(false)
    const [chosenArtist, setChosenArtist] = useState("")
    const [artistBio, setArtistBio] = useState(false);
    const [aboutPage, setAboutPage] = useState(false)

    function setArtistPage(){
      artists ? setArtists(false) : setArtists(true)
    }

    function openBio() {
        artistBio ? setArtistBio(false) : setArtistBio(true)
      }


    function chooseArtist(artist) {
        artist ? setChosenArtist(artist) : setChosenArtist("") && openBio()
    }


    function aboutUs() {
        aboutPage ? setAboutPage(false) : setAboutPage(true)
    }

  
 

        return (
            <>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Permanent Makeup Studio & Training Academy | Bethlehem, Easton, Allentown, Philly, New York | Pennsylvania, New Jersey, New York, Delaware, Maryland, and beyond</title> 
                <link rel="canonical" href="https://www.cosmetink.com/" />
                <meta name="description" content="Eyebrows, Microblading, Nano Brows, Ombre Brows, Lip Blush & Permanent Eyeliner Services" />
                <meta name="keywords" content="eyebrows, microblading, ombre brows, permanent makeup, lip blush, best microblading, tattoo makeup, cosmetic ink, permanent makeup, microblading, semi-permanent makeup, microbladed eyebrows, microblading de cejas" />
            </Helmet>    

            {artists  && <ArtistSidebar setArtists={setArtists} chooseArtist={chooseArtist}/>
             }

             {chosenArtist && 
             <ArtistPage selectedArtist={chosenArtist} chooseArtist={chooseArtist} openBio={openBio} artistBio={artistBio} />}

            {aboutPage && 
             <AboutUs aboutUs={aboutUs} />}



       
            <div className='main-title c blk'>

                <Link to="/">
                <div className='title2 c studio'>
                        COSMET<span className='moving-grad2'>INK</span><span className='reg'>
                        ®</span>STUDIO
                        <div className='title3'>
                        The Premier Permanent Makeup Studio<br/>in the Lehigh Valley
                        </div>
                </div>
                </Link>

            </div>
             
            <div className='gradient-underline'></div>

            <div className='navbar-row-a'>
                
                <Link to="/treatments" href="https://studio.cosmetink.com" className='top-links' alt="Permanent Makeup Studio in Lehigh Valley, Pennsylvania. Nanoblading, Nano Brows, Microblading, Ombré Brows, and Lip Blushing">
                    Treatments
                 </Link>
                 <Link onClick={setArtistPage} className='top-links' alt="Permanent Makeup Training Classes Academy in  Lehigh Valley, Pennsylvania. Learn Permanent Makeup. Get Certified Today.">
                   View Artists & Their Work
                 </Link>
                 <Link onClick={setAboutPage}  href="https://studio.cosmetink.com" className='top-links' alt="Permanent Makeup Studio in Lehigh Valley, Pennsylvania. Nanoblading, Nano Brows, Microblading, Ombré Brows, and Lip Blushing">
                      About Us
                </Link>
 
            </div>
              
              <div className='navbar-row'>
                <div className='navbar-row-inside'>
                    <Link to="/policies" className='top-links2' alt="Permanent Makeup Studio in Lehigh Valley, Pennsylvania. Nanoblading, Nano Brows, Microblading, Ombré Brows, and Lip Blushing">
                        Policies
                    </Link>
                    <Link to="/preparation" className='top-links2' alt="Permanent Makeup Studio in Lehigh Valley, Pennsylvania. Nanoblading, Nano Brows, Microblading, Ombré Brows, and Lip Blushing">
                      Pre-Care
                    </Link>
                    <Link to="/process" className='top-links2' alt="Permanent Makeup Studio in Lehigh Valley, Pennsylvania. Nanoblading, Nano Brows, Microblading, Ombré Brows, and Lip Blushing">
                      The Process
                    </Link>
                    <Link to="/financing" className='top-links2' alt="Permanent Makeup Studio in Lehigh Valley, Pennsylvania. Nanoblading, Nano Brows, Microblading, Ombré Brows, and Lip Blushing">
                      Financing
                    </Link>
                    <a href={`https://squareup.com/gift/975FTZ6P76NK6/order`} className='top-links2' alt="Permanent Makeup Studio in Lehigh Valley, Pennsylvania. Nanoblading, Nano Brows, Microblading, Ombré Brows, and Lip Blushing">
                      Gift Cards
                    </a>
                    <Link to="/contact" className='top-links2' alt="Permanent Makeup Studio in Lehigh Valley, Pennsylvania. Nanoblading, Nano Brows, Microblading, Ombré Brows, and Lip Blushing">
                      Contact
                    </Link>
             
                </div>
              </div>


         <div className='bottom-course-nav'>
            <div className='right-btn'>
                <a href="https://app.acuityscheduling.com/schedule.php?owner=17444389">
                                <div className="action-button2">
                                    <div className="action-button-text2">Book Now</div>
                                </div>
                </a>
             </div>

                <div className='left-words poppins-medium'>
                    Ready to book?
                    <div className='left-words-2 poppins-regular-italic'>
                        <span className='line'></span>
                         See Artist Availabilities & Pricing
                     </div>
     
                </div>

                <div className='financing-bar'>
                   <p className='smaller'>
                    30 Second Financing Approval <a href="mailto:cosmetinkbeauty@gmail.com?subject=30 Second Credit Application&body=Please send me a credit application to see how much I'm approved for.">Apply Now</a>
                    <Link alt="COSMETINK financing" to="/financing" className='learn-more'>Learn More</Link>
                   </p>

                </div>

        </div>
            </>
            ) 
       
        
    }
